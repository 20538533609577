"use strict"

/**
Note on label naming convention:

Industry standard for layout of pages are x,y, and for labels are y,x. We use
x,y for the human readable names, and y,x for all other label size naming,
including HTML IDs.

Periods, such as 1.625 are denoted with a p. e.g. 1p625. This is to avoid
potential conflicts, such as `.` in scss files, potential file naming, etc.
Human readable formats use the period instead of p.
 */

import * as QR from "./qr.js";

export {
	DefaultLabelOptions,
	Labels,

	CreateLabelElement,
	CreateACLabel,
	CustomPrintCSS,
};

/**
LabelPredefinedTemplateClass is the identifier for label sizes and styling.
Example: `p_2p625x1` = Cyphr.me's standard 2.625" x 1" long labels.
@typedef {string} LabelPredefinedTemplateClass
 */

/**
LabelOptions are used for global/stateful variables used for labels that are are
held in an object, and can be passed around to funcs and modules.

- className: Label's class name for identifying stylesheets per page.
- labelsPerPage: How many labels fit on a page with current dimensions.
- labelBrandingLevel: Level of branding styling to use. See 'setLabelBranding in
  labels.js'.
- acTitle: Text for displaying on the label. Defaults to empty string.
- testACTitle: Bool for debugging that populates test title text on labels.
- labelsGenerated: Current number of ACs imported for printing.
- labelVertical: Bool for vertical label styling.
- labelRotate90: Bool for rotating label 90 degrees.
- labelCustomLogo: Bool for using custom logo in leu of title text.
- labelCustomLogoImageLink: Image link for displaying custom logo.
- startAtLabel: Position in current array of ACs to start at for printing.
- startPosition: Starting position on the page for where to start printing.
- isPrintViewInited: Whether or not the print view has been initialized.
- noHeader: Removes header and footer with trademark and patent when set to
  true.
- blankHeader: Preserves header formatting and spacing, but is blank when set to
  true.
@typedef  {object}                         LabelOptions
@property {LabelPredefinedTemplateClass}   className
@property {number}                         labelsPerPage
@property {string}                         labelBrandingLevel // TODO enum
@property {string}                         acTitle
@property {boolean}                        testACTitle
@property {number}                         labelsGenerated
@property {boolean}                        labelVertical
@property {boolean}                        labelRotate90
@property {boolean}                        labelCustomLogo
@property {string}                         labelCustomLogoImageLink
@property {number}                         startAtLabel
@property {number}                         startPosition
@property {boolean}                        isPrintViewInited
@property {boolean}                        noHeader
@property {boolean}                        blankHeader
 */

/**
CustomPrintParams hold the custom styling options for stickers/labels, as well
as page styling.

TODO support other measurements in the future, cm, pixels, etc.

Pages are split up by Top Margin, Bottom Margin, and Inner Page, which is
between the top and bottom margin.

Numbers are currently all in terms of inches.

id: The id is the identifier for label templates that have predefined
CustomPrintParams. The id is the same as the key in 'Labels' but needed because
once objects are passed, the key used to fetch the object from labels do not
carry.

- pageHeight: Height of each page
- pageWidth: Width of each page 
- pageMarginTop: Top margin of each page
- pageInnerPadLeft: Left padding of inner page
- labelHeight: Height of each label
- labelWidth: Width of each label
- pageMarginBottom: Bottom margin of each page
- pageMarginTopPaddingTop: Page's top margin's top padding
- pageMarginBottomPaddingTop: Page's bottom margin's top padding
- labelContentsPaddingTop: Labels HR Div's top padding
- labelContentsMarginLeft: Labels HR Div's left margin
- labelMarginLeft: Left margin of each label
- labelAuthTitleHeight: Authentic and title sections height
- labelAuthTitlePaddingTop: Authentic and title top padding
- labelLogoHeight: HR Div img element max height
- labelTitleLogoHeight: AC Title img element max height (inside HR Div)
- labelInnerPaddingTop: Inner div of label top padding
- labelInnerPaddingLeft: Inner div of label left padding
- labelQRSize: Label's QR size
- humanReadableNames: Array of human readable measurements and names.
  e.g. ['1" x 2"', '1" x 2-5/8"']

# Boolean options:
- pageLandscape: Sets the page to landscape view 
- highlight: Highlights components of the print preview.
- outline: Outlines components of the label in print preview.

# Internal read only values used by labels module:
- isCustom: If true, all fields are modifiable.
- LabelOptions: Directly embedded label options object.
@typedef  {object}  CustomPrintParams
@property {string}  id
@property {number}  pageHeight
@property {number}  pageWidth
@property {number}  pageMarginTop
@property {number}  pageInnerPadLeft
@property {number}  labelHeight
@property {number}  labelWidth
@property {number}  pageMarginBottom
@property {number}  pageMarginTopPaddingTop
@property {number}  pageMarginBottomPaddingTop
@property {number}  labelContentsPaddingTop
@property {number}  labelContentsMarginLeft
@property {number}  labelMarginLeft
@property {number}  labelAuthTitleHeight
@property {number}  labelAuthTitlePaddingTop
@property {number}  labelLogoHeight
@property {number}  labelTitleLogoHeight
@property {number}  labelInnerPaddingTop
@property {number}  labelInnerPaddingLeft
@property {number}  labelQRSize

@property {Array}    humanReadableNames

// Bool Options
@property {boolean} pageLandscape
@property {boolean} highlight
@property {boolean} outline

// Internal read only values
@property {boolean} isCustom

// Directly embedded LabelOptions.
@property {...LabelOptions}
 */

/** @type {LabelOptions} */
const DefaultLabelOptions = {
	"labelCustomLogo": false,
	"acTitle": "",
	"testACTitle": false,
	"labelVertical": false,
	"labelBrandingLevel": "LogoAuthBrand",
	"labelsGenerated": 0,
	"isPrintViewInited": false,
	"labelsPerPage": 0,
	"pageClass": "",
	"startAtLabel": 0,
	"startPosition": 0,
};

/**
CreateLabelElement accept an ID (Anti-Counterfeit's ID) and a full QR URL to
create the inner contents of a label, and return the label div element.

Example of how to call this function and append the label to a parent div on the
page: `div.appendChild(await CreateLabel(id, qrURL, lblOpts));`
@param   {string}       qrURL           Full QR URL for the label's QR.
@param   {LabelOptions} [lblOpts]       Label options object.
@returns {HTMLElement}  printLabel      HTML print label element.
@throws  {error}
 */
async function CreateLabelElement(qrURL, lblOpts) {
	let printLabel = document.querySelector('#LabelTemplate').content.cloneNode(true).querySelector('.printLabel');
	let innerDiv = printLabel.querySelector('.inner');
	let hrDiv = document.querySelector("#HRDiv").content.cloneNode(true).querySelector('.hrDiv');

	if (isEmpty(lblOpts)) {
		lblOpts = DefaultLabelOptions;
	}

	setLabelBranding(hrDiv, lblOpts);

	// QR for Long Labels are aligned to the left of the HR Div, and in the
	// middle of the HR Div for Vertical Labels.
	let qr = await QR.CreateQR(qrURL);
	if (lblOpts.labelVertical) {
		hrDiv.querySelector('.cyphrmeLogo').insertAdjacentHTML('afterEnd', qr.outerHTML);
	} else {
		innerDiv.append(qr);
	}

	innerDiv.append(hrDiv);
	return printLabel;
}

/**
CreateLabel accepts an ID (Anti-Counterfeit's ID) and generates a label to
be added to the given div passed in.
@param   {HTMLElement}                  parentDiv HTML parent div for appending label.
@param   {string}                       qrURL     Full QR URL for the label's QR.
@param   {LabelPredefinedTemplateClass} [templ]   Template for Label Size parameters.
@param   {LabelOptions}                 [lblOpts] Label options object.
@returns {void}
@throws  {error}
 */
async function CreateACLabel(parentDiv, qrURL, templ, lblOpts) {
	let template = Labels[templ];
	if (isEmpty(template)) {
		template = Labels["p_1x2p625"]; // Default
	}
	// console.debug("ParentDiv: ", parentDiv, "QRURL: ", qrURL, "Templ: ", templ, "LblOpts: ", lblOpts, "Template: ", template)

	// Custom does not have predefined template, and must be given.
	if (templ === 'Custom') {
		template = lblOpts
	} else if ("acTitle" in lblOpts) {
		template.acTitle = lblOpts.acTitle // For when custom label titles are specified.
	}

	// Pass custom class so that other styles apply to the page. Otherwise, if
	// passed empty, all styles are under the parent class 'customSize' and
	// only the last style would be applied to all.
	let css = CreateCustomLabelCSS(template, "." + templ)
	// console.debug(css)

	// Set custom CSS in a style element on the current page.
	let s = document.createElement('style')
	s.id = "customStyle_" + templ
	s.textContent = css
	document.querySelector('head').append(s)

	Show(parentDiv)

	parentDiv.classList.add(templ)
	parentDiv.append(await CreateLabelElement(qrURL, template))
	// parentDiv.append(await CreateLabelElement(qrURL, lblOpts))
};

/**
setLabelBranding accepts an HR Div element and sets the branding specific
styling, based on the selected branding level.

Enumerate Sticker Brand Level:

TODOs:
Logo 
LogoAuth
LogoAuthTitle

Level for which label branding style to use. We currently support 6 levels
of branding:
1.) LogoAuthBrand (Default): Cyphr.me Logo -> Authentic -> Custom Logo / Title
2.) BrandAuthLogo: Custom Logo -> Authentic -> Cyphr.me Logo
3.) BrandAuthIconTitle: Custom Logo -> Authentic + Cyphr.me Bolt -> Custom title
4.) BrandTitle: Custom Logo -> Custom title
5.) BrandLogo: Custom Logo -> Cyphr.me Logo
6.) Brand: Custom Logo
@param   {HTMLElement}   hrDiv     Label's HR Div.
@param   {LabelOptions}  lblOpts   Label options object.
@returns {void}
 */
function setLabelBranding(hrDiv, lblOpts) {
	// console.debug(lblOpts)
	let isTitleSet = false;
	let customMainLogo = true;

	switch (lblOpts.labelBrandingLevel) {
		default: // Default is `LogoAuthBrand`
		case "LogoAuthBrand":
			// Replace title with custom logo if option checked, otherwise, proceed
			// as normal.
			if (lblOpts.labelCustomLogo) { // Logo instead of text
				hrDiv.querySelector('.acTitle .titleLogo').src = lblOpts.labelCustomLogoImageLink;
				isTitleSet = true;
			}
			customMainLogo = false
			break
		case "BrandAuthLogo":
			isTitleSet = true;
			hrDiv.querySelector('.acTitle .titleLogo').src = '/assets/img/cyphrme_long_500x135.png'; // TODO enum link
			break
		case "BrandAuthIconTitle":
			Show(hrDiv.querySelector('.authentic .bolt'));
			break
		case "BrandTitle":
			Hide(hrDiv.querySelector('.authentic'));
			if (lblOpts.labelCustomLogoImageLink === "") {
				lblOpts.labelCustomLogoImageLink = "/assets/img/cyphrme_long_500x135.png"
			}
			break
		case "BrandLogo":
			Hide(hrDiv.querySelector('.authentic'));
			isTitleSet = true
			hrDiv.querySelector('.acTitle .titleLogo').src = '/assets/img/cyphrme_long_500x135.png';
			break
		case "Brand":
			Hide(hrDiv.querySelector('.authentic'));
			Hide(hrDiv.querySelector('.acTitle'))
			if (lblOpts.labelCustomLogoImageLink === "") {
				lblOpts.labelCustomLogoImageLink = "/assets/img/cyphrme_long_500x135.png"
			}
			break
	}

	if (customMainLogo) {
		hrDiv.querySelector('.cyphrmeLogo img').src = lblOpts.labelCustomLogoImageLink;
	}

	if (!isTitleSet) {
		// Pull the AC title
		if (lblOpts.testACTitle) { // Test text
			hrDiv.querySelector('.acTitle').textContent = "Cyphr.me Title";
		} else { // Text
			hrDiv.querySelector('.acTitle').textContent = lblOpts.acTitle;
		}
	}
}


/**
CustomPrintCSS generates custom CSS for printing from fields in the custom print
form and applies it to the page in a newly created style element.

State grows when calling this function. If needing to clear, that must be done
prior to this call. e.g. : document.querySelector('#customStyle').remove();
@param   {CustomPrintParams} cpp
@returns {void}
 */
function CustomPrintCSS(cpp, templ) {
	//// Generate custom CSS. Other styles in use can be found in 'labels.css'.
	// Precedence: Origin -> Page -> Margin Top -> Margin Bottom -> Page Inner
	let css = CreateCustomPageCSS(cpp, templ) + CreateCustomLabelCSS(cpp, templ);
	// console.debug(css);

	//// Set custom CSS in a style element on the current page.

	let s = document.createElement('style');
	s.id = "customStyle"
	s.textContent = css;
	document.querySelector('head').append(s);

	//// If needing to download CSS to a file.
	// Function to download data to a file.
	// Stolen from: https://stackoverflow.com/a/30832210/15147681
	// let download = function (data, filename, type) {
	// 	var file = new Blob([data], {
	// 		type: type
	// 	});
	// 	if (window.navigator.msSaveOrOpenBlob) // IE10+
	// 		window.navigator.msSaveOrOpenBlob(file, filename);
	// 	else { // Others
	// 		var a = document.createElement("a"),
	// 			url = URL.createObjectURL(file);https://localhost:8081/api/v1/b/file/U04tb5U74_vVhevufEgH0XskNyGM2U49NV25DBHZ-Xg
	// 			document.body.removeChild(a);
	// 			window.URL.revokeObjectURL(url);
	// 		}, 0);
	// 	}
	// }
	//
	// download(css, "custom.css", "text/plain");
}

/**
CreateCustomPageCSS generates and returns custom CSS styles for elements that
are used for page styling.

All whitespace/new lines/tabs inside of `` marks is relevant for CSS output
and should not be removed.

`templ` must be passed with the prepended '.', because it is a CSS selector
string for the label class.
@param   {CustomPrintParams}             cpp
@param   {LabelPredefinedTemplateClass}  [templ]
@returns {string}                        css
 */
function CreateCustomPageCSS(cpp, templ) {
	if (isEmpty(templ)) {
		templ = '.customSize';
	}
	// Margin bottom is implicit if not explicitly given. Margin bottom with an
	// input of 0 is interpreted as empty from URL form. If wanting the default
	// margin bottom, the input field should be blank. If wanting to set the
	// margin bottom to 0, the value must be '0.0'.
	let margBottom = `\n`;
	if (!isEmpty(cpp.pageMarginBottom) || cpp.pageMarginBottom >= 0) {
		// margBottom += `height:` + cpp.pageMarginBottom + `in;\n`;
		if (cpp.pageMarginBottom === 0) {
			margBottom += `padding: 0;\n`
		}
	}

	// Rotate page 90 degrees for landscape view.
	let landscape = ``;
	if (cpp.pageLandscape) {
		landscape = `
.page.flip {
transform: rotate(-90deg) translateX(-` + cpp.pageWidth + `in);
transform-origin: top left;
}

`;
	}

	//// Generate custom CSS. Other styles in use can be found in 'labels.css'.
	// Precedence: Origin -> Page -> Margin Top -> Margin Bottom -> Page Inner
	let css = `
.page {
	height:` + cpp.pageHeight + `in;
	width:` + cpp.pageWidth + `in;
}

.margin {
	height:` + cpp.pageMarginTop + `in;
}

.marginTop {
padding-top:` + cpp.pageMarginTopPaddingTop + `in;
}

.marginBottom {
padding-top:` + cpp.pageMarginBottomPaddingTop + `in;` +
		margBottom +
		`}

` + templ + ` > .pageInner {
padding-left:` + cpp.pageInnerPaddingLeft + `in;
}
` + landscape;

	return css;
}


/**
CreateLabelCSS generates and returns custom label CSS for the given custom
print params object.

All whitespace/new lines/tabs inside of `` marks is relevant for CSS output
and should not be removed.
@param   {CustomPrintParams}             cpp
@param   {LabelPredefinedTemplateClass}  [templ]
@returns {string}                        css
 */
function CreateCustomLabelCSS(cpp, templ) {
	if (isEmpty(templ)) {
		templ = '.customSize';
	}
	// Authentic and AC Title Font Size is calculated based upon the given 
	// label's Authentic Height. The font is 1/8th smaller than the height.
	// If no height is given, the default font size is 10 pixels.
	let authenticAndTitleFontSize = cpp.labelAuthTitleHeight * .875;
	if (isEmpty(cpp.labelAuthTitleHeight) || cpp.labelAuthTitleHeight <= 0) {
		authenticAndTitleFontSize = 0.1; // ~ 10px
	}

	// Vertical
	let qrFloat = "left;";
	if (cpp.labelVertical) {
		qrFloat = `none;
display: inline-block;`;
	}

	// Rotate clockwise 90 degrees.
	// A 90 degree rotation always require a translation.
	let r90 = ``;
	if (cpp.labelRotate90) {
		// Flip width and height for intermediate before rotate.
		r90 = templ + ` .printLabel .r90 {
transform: rotate(.25turn) translate(0in, -` + cpp.labelWidth + `in);
transform-origin: top left;
}

` + templ + ` .printLabel .inner {
width:` + cpp.labelHeight + `in;
height:` + cpp.labelWidth + `in;
}

`;
	}

	// Generate custom label CSS. Other styles in use can be found in 'labels.css'.
	let css = templ + ` .hrDiv {
padding-top:` + cpp.labelContentsPaddingTop + `in;
margin-left:` + cpp.labelContentsMarginLeft + `in;
}

` + templ + ` .printLabel {
width:` + cpp.labelWidth + `in;
height:` + cpp.labelHeight + `in;
margin-left:` + cpp.labelMarginLeft + `in;
}

` + templ + ` .printLabel .hrDiv .cyphrmeLogo img {
max-height:` + cpp.labelLogoHeight + `in;
}

` + templ + ` .printLabel .hrDiv .acTitle img {
max-height:` + cpp.labelTitleLogoHeight + `in;
}

` + templ + ` .printLabel .inner {
padding-top:` + cpp.labelInnerPaddingTop + `in;
padding-left:` + cpp.labelInnerPaddingLeft + `in;
}

` + templ + ` .printLabel .qr_div {
width:` + cpp.labelQRSize + `in;
height:` + cpp.labelQRSize + `in;
float:` + qrFloat + `
}

` + templ + ` .printLabel .authentic {
padding-top:` + cpp.labelAuthTitlePaddingTop + `in;
}

` + templ + ` .printLabel .authentic span {
font-size:` + authenticAndTitleFontSize + `in;
}

` + templ + ` .printLabel .authentic img {
height:` + cpp.labelAuthTitleHeight + `in;
}

` + templ + ` .printLabel .acTitle {
padding-top:` + cpp.labelAuthTitlePaddingTop + `in;
font-size:` + authenticAndTitleFontSize + `in;
}` + r90;

	return css;
}

////////////////////////////////////////////////////////////////////////////////
// Enums
////////////////////////////////////////////////////////////////////////////////

// Standard US Letter page size.
let standardUSLetter = {
	pageHeight: 11,
	pageWidth: 8.5,
};

// Standard roll size.
let standardRoll = {
	pageHeight: 110,
	pageWidth: 2.5,
};


// Standard 1/2" margins for top and bottom with batch numbers.
let standardCyphrmeMargins = {
	"pageMarginTop": .5,
	"pageMarginBottom": 0,
	"pageMarginTopPaddingTop": .22,
	"pageMarginBottomPaddingTop": .03,
}

// Standard level 1 branding.
let cyphrmeBrandingLvl1 = {
	"labelCustomLogoImageLink": "",
	"labelBrandingLevel": "LogoAuthBrand",
}

// For labels with no checkbox options checked.
let noCheckboxOpts = {
	"pageLandscape": false,
	"labelVertical": false,
	"labelRotate90": false,
	"labelCustomLogo": false,
	"noHeader": false,
	"blankHeader": false,
}

// For debugging and print preview styling that does not get printed.
let noDebugFields = {
	"highlight": false,
	"outline": false,
	"startAtLabel": 0,
	"startPosition": 0,
}

//// Label Sizes

/**
`className` Must only be set if the className differs from the 'id'.
 @type {{LabelPredefinedTemplateClass:CustomPrintParams}}
 */
const Labels = {
	// Custom preserves and enables all fields.
	"Custom": {
		"id": "Custom",
		"className": "customSize",
	},
	// Blank clears and enables all fields and sets the current label size option
	// to custom.
	"Blank": {
		"id": "Blank",
		"className": "customSize",
		"pageHeight": 0,
		"pageWidth": 0,
		"pageMarginTop": 0,
		"pageMarginBottom": 0,
		"pageMarginTopPaddingTop": 0,
		"pageMarginBottomPaddingTop": 0,
		"pageInnerPaddingLeft": 0,
		"labelHeight": 0,
		"labelWidth": 0,

		...noDebugFields,
		// Debugging values
		...noDebugFields,
		// Checkbox values
		...noCheckboxOpts,
		// Title Logo options
		...cyphrmeBrandingLvl1,

		// Set non required fields
		"labelContentsPaddingTop": 0,
		"labelContentsMarginLeft": 0,
		"labelMarginLeft": 0,
		"labelAuthTitleHeight": 0,
		"labelAuthTitlePaddingTop": 0,
		"labelLogoHeight": 0,
		"labelTitleLogoHeight": 0,
		"labelInnerPaddingTop": 0,
		"labelInnerPaddingLeft": 0,
		"labelQRSize": 1,
		"labelsPerPage": 0,
	},
	// Standard size - 1" x 2.625"
	"p_1x2p625": {
		"id": "p_1x2p625",
		"humanReadableNames": ['1" x 2.625"', '1" x 2-5/8"'],
		...standardUSLetter,
		...standardCyphrmeMargins,

		"pageInnerPaddingLeft": .0625, // Don't round
		"labelHeight": 1,
		"labelWidth": 2.625,

		// Debugging values
		...noDebugFields,
		// Checkbox values
		...noCheckboxOpts,
		// Title Logo options
		...cyphrmeBrandingLvl1,

		// Set non required fields
		"labelContentsPaddingTop": .03,
		"labelContentsMarginLeft": .1,
		"labelMarginLeft": .125,
		"labelAuthTitleHeight": 0.15,
		"labelAuthTitlePaddingTop": .03,
		"labelLogoHeight": 0.37,
		"labelTitleLogoHeight": .25,
		"labelInnerPaddingTop": .12,
		"labelInnerPaddingLeft": .165,
		"labelQRSize": .78,
		"labelsPerPage": 30,
	},
	// 1" x 2"
	"p_1x2": {
		"id": "p_1x2",
		"humanReadableNames": ['1" x 2"'],
		...standardUSLetter,
		...standardCyphrmeMargins,

		"pageInnerPaddingLeft": 0.25,
		"labelHeight": 1,
		"labelWidth": 2,

		// Debugging values
		...noDebugFields,

		// Checkbox values
		...noCheckboxOpts,

		// Title Logo options
		...cyphrmeBrandingLvl1,

		// Set non required fields
		"labelContentsPaddingTop": 0,
		"labelContentsMarginLeft": 0,
		"labelMarginLeft": 0,
		"labelAuthTitleHeight": 0.17,
		"labelAuthTitlePaddingTop": .03,
		"labelLogoHeight": 0.3,
		"labelTitleLogoHeight": .25,
		"labelInnerPaddingTop": .125,
		"labelInnerPaddingLeft": .09,
		"labelQRSize": .78,
		"labelsPerPage": 40,
	},
	// 1/2" x 1-3/4"
	"p_05x1p75": {
		"id": "p_05x1p75",
		...standardUSLetter,
		...standardCyphrmeMargins,

		"pageInnerPaddingLeft": 0,
		"labelHeight": .5,
		"labelWidth": 1.75,

		// Debugging values
		...noDebugFields,

		// Checkbox values
		...noCheckboxOpts,

		// Title Logo options
		...cyphrmeBrandingLvl1,

		// Set non required fields
		"labelContentsPaddingTop": 0,
		"labelContentsMarginLeft": 0,
		"labelMarginLeft": 0.3,
		"labelAuthTitleHeight": 0.09,
		"labelAuthTitlePaddingTop": .01,
		"labelLogoHeight": .22,
		"labelTitleLogoHeight": .15,
		"labelInnerPaddingTop": .04,
		"labelInnerPaddingLeft": .06,
		"labelQRSize": .4,
		"labelsPerPage": 80,
	},

	// 1" x 1"
	"p_1x1": {
		"id": "p_1x1",
		"humanReadableNames": ['1" x 1"'],
		...standardUSLetter,
		...standardCyphrmeMargins,

		"pageInnerPaddingLeft": 0.25,
		"labelHeight": 1,
		"labelWidth": 1,

		// Debugging values
		...noDebugFields,

		// Checkbox values
		"pageLandscape": false,
		"labelVertical": true,
		"labelRotate90": false,
		"labelCustomLogo": false,
		"noHeader": false,
		"blankHeader": false,
		// Title Logo options
		...cyphrmeBrandingLvl1,

		// Set non required fields
		"labelContentsPaddingTop": 0,
		"labelContentsMarginLeft": 0,
		"labelMarginLeft": 0,
		"labelAuthTitleHeight": 0.1,
		"labelAuthTitlePaddingTop": .02,
		"labelLogoHeight": .23,
		"labelTitleLogoHeight": .15,
		"labelInnerPaddingTop": .05,
		"labelInnerPaddingLeft": .03,
		"labelQRSize": .43,
		"labelsPerPage": 80,
	},

	// 1" x 2" vertical
	"p_1x2_vertical": {
		"id": "p_1x2_vertical",
		"className": "customSize",
		"humanReadableNames": ['1" x 2" vertical'],
		...standardUSLetter,
		...standardCyphrmeMargins,

		"pageInnerPaddingLeft": 0.25,
		"labelHeight": 1,
		"labelWidth": 2,

		// Debugging values
		...noDebugFields,

		// Checkbox values
		"pageLandscape": true,
		"labelVertical": true,
		"labelRotate90": true,
		"labelCustomLogo": false,
		"noHeader": false,
		"blankHeader": false,

		// Title Logo options
		...cyphrmeBrandingLvl1,

		// Set non required fields
		"labelContentsPaddingTop": 0,
		"labelContentsMarginLeft": 0,
		"labelMarginLeft": 0,
		"labelAuthTitleHeight": 0.15,
		"labelAuthTitlePaddingTop": .03,
		"labelLogoHeight": .25,
		"labelTitleLogoHeight": .15,
		"labelInnerPaddingTop": .2,
		"labelInnerPaddingLeft": 0,
		"labelQRSize": .78,
		"labelsPerPage": 40,

		// Set internal fields
		//"isCustom": true,
	},

	// Standard size - 1" x 2.625" w/ level 2 branding example.
	"p_1x2p625_branded": {
		"id": "p_1x2p625_branded",
		"className": "customSize",
		"humanReadableNames": ['1" x 2.625" branded'],
		...standardUSLetter,
		...standardCyphrmeMargins,

		"pageInnerPaddingLeft": .0625, // Don't round
		"labelHeight": 1,
		"labelWidth": 2.625,

		// Debugging values
		...noDebugFields,
		// Checkbox values
		"pageLandscape": false,
		"labelVertical": false,
		"labelRotate90": false,
		"labelCustomLogo": true,
		"noHeader": false,
		"blankHeader": false,
		// Title Logo options
		"labelCustomLogoImageLink": "/assets/img/tankmatez_long.jpg",
		"labelBrandingLevel": "BrandAuthLogo",

		// Set non required fields
		"labelContentsPaddingTop": .03,
		"labelContentsMarginLeft": .1,
		"labelMarginLeft": .125,
		"labelAuthTitleHeight": 0.15,
		"labelAuthTitlePaddingTop": .03,
		"labelLogoHeight": 0.28,
		"labelTitleLogoHeight": .2,
		"labelInnerPaddingTop": .125,
		"labelInnerPaddingLeft": .16,
		"labelQRSize": .78,
		"labelsPerPage": 30,

		// Set internal fields
		//"isCustom": true,
	},
	//////////////////////////////////////////////////////////////////////////////
	// Rolls
	//////////////////////////////////////////////////////////////////////////////
	// 1" x 2"
	"p_1x2_roll": {
		"id": "p_1x2_roll",
		"humanReadableNames": ['1" x 2" roll'],
		...standardRoll,

		"pageMarginTop": 0.25,
		"pageMarginBottom": 0,
		"pageMarginTopPaddingTop": 0,
		"pageMarginBottomPaddingTop": 0,

		"pageInnerPaddingLeft": 0.25,
		"labelHeight": 1,
		"labelWidth": 2,

		// Debugging values
		...noDebugFields,

		// Checkbox values
		"pageLandscape": false,
		"labelVertical": false,
		"labelRotate90": false,
		"labelCustomLogo": false,
		"noHeader": false,
		"blankHeader": true,

		// Title Logo options
		...cyphrmeBrandingLvl1,

		// Set non required fields
		"labelContentsPaddingTop": 0.04,
		"labelContentsMarginLeft": 0,
		"labelMarginLeft": 0,
		"labelAuthTitleHeight": 0.17,
		"labelAuthTitlePaddingTop": .03,
		"labelLogoHeight": 0.28,
		"labelTitleLogoHeight": .2,
		"labelInnerPaddingTop": 0.1,
		"labelInnerPaddingLeft": .09,
		"labelQRSize": .78,
		"labelsPerPage": 110,
	},

};