"use strict"

import * as Cyphrme from './cyphrme.js'
import * as Cva from './cva.js'

export {
	BundleTypes,

	// NewBLS,
	BundleCVAsFromTree,
	BLSItemCount,
	BLSTopToString,
	DecrementBundle,
	BLSToGUIString,

}

/**
@typedef {import ('../page/ac_gen_pj.js').PJ}     PJ
@typedef {import ('../lib/tree.js').Tree}         Tree
@typedef {import ('../lib/tree.js').BLS}          BLS

@typedef {string}                                 Bundle  e.g. "item", "page", or "batch" etc.
*/

// Bundle Types
const btItem = "item"
const btPage = "page"
const btBatch = "batch"
const btBox = "box"
const btPallet = "pallet"
const btRack = "rack"
const btWarehouse = "warehouse"

// Enumerated and supported Bundle types. Also known as `bundle_type`.
const BundleTypes = [btItem, btPage, btBatch, btBox, btPallet, btRack, btWarehouse]

/**
BundleCVAsFromTree generates bundle CVAs recursively from tree. Bundles are
populated according to branch_levels in tree. E.g. a bundle with depth sizes:
[1, 5, 10, 30] means 1 pallet, with 5 boxes each containing 10 pages of 30 items
(labels), which is 56 different bundles.
@param   {Tree}         tree
@returns {BundleCVAs}
*/
async function BundleCVAsFromTree(tree) {
	// console.log("BundleCVAsFromTree", tree)
	let BCVA = []
	if (isEmpty(tree.children)) {
		return BCVA
	}
	let numerator = 0
	let denominator = tree.children.length
	for (let child of tree.children) {
		numerator++
		let thing = {
			id: child.id,
			parent: tree.id,
			determ: true,
			bundle_type: BundleTypes[child.branch_level_sizes.length],
			numer: numerator,
			denom: denominator,
			n_childs: child.branches.length
		}
		if (!isEmpty(child.children)) { // children are empty on leaves.
			thing.n_childs = child.children.length
		}
		BCVA.push(await Cva.BundleCreate(thing))
		BCVA = BCVA.concat(await BundleCVAsFromTree(child)) // Recursive call.
	}

	return BCVA
}

/**
decrementBundle decrements the Bundle Level to the next level down.  Errors on
invalid bundle types.  If the bottom level, "item", "item" is returned.
@param   {Bundle} bundle     bundle type.
@returns {Bundle}
*/
function DecrementBundle(bundle) {
	switch (bundle) {
		default:
			Cyphrme.Error("bundle is an undefined category: " + bundle)
		case btItem: // Last level (ACs, stickers, thing), do nothing.
			break
		case btPage:
			bundle = btItem
			break
		case btBatch:
			bundle = btPage
			break
		case btBox:
			bundle = btBatch
			break
		case btPallet:
			bundle = btBox
			break
		case btRack:
			bundle = btPallet
			break
		case btWarehouse:
			bundle = btRack
	}
	return bundle
}

/**
BLSTopToString converts the top brach_level_sizes to string, e.g. [1,1,3]
converts to "batch". 
@param   {BLS}      bls 
@param   {Bundle}
*/
function BLSTopToString(bls) {
	let level = bls.length - 1
	if (level >= BundleTypes.length) {
		Cyphrme.Error("Unsupported batch depth.")
	}
	return BundleTypes[level]
}

/**
BLSItemCount calculates how many total items in the entire tree is in a brach_level_sizes 
@param   {BLS}      bls 
@param   {number}
*/
function BLSItemCount(bls) {
	// console.log(bls);
	let sum = 0
	for (let depth of bls) {
		if (sum === 0) {
			sum = depth
			continue
		}
		sum *= depth
	}
	return sum
}

/**
BLSItemCount calculates how many total items in the entire tree is in a brach_level_sizes 
@param   {string}   s    Bracketless BLS e.g. `"1,1,30"`
@param   {BLS}
*/
function StringToBLS(s) {
	return JSON.parse("[" + s + "]")
}

/**
BLSToGUIString returns the GUI representation of BLS.
@param   {BLS}      bls    BLS e.g. `[1,1,30]`
@param   {string}                    `1,1,30`
*/
function BLSToGUIString(bls) {
	let s = JSON.stringify(bls)
	return s.substring(1, s.length - 1)
}