"use strict";

import * as Lib from '../lib/lib~fv=00000000.min.js';

/**
@typedef {import('../../../pkg/cozejs/key.js').CozeKey} CozeKey
 **/

export {
	Site,
	CyphrMeSite,
	API,
	CyphrmeLogoLong,
	Page,
	MaxFileSize,
	QRCodeURL,

	Log,

	AddOnload,
	AddOnloadFirst,
	RemoveOnload,

	ParseSerializedCozeKey,

	IsCyphrmeDigest,
	IsHex,
	IsBASE37,
	SetLogLevel,
	UTKtoHR,
	UnixToHR,

	Error,
	Notification,

	JSONPretty,
	JumpToAnchor,
	Collapse,
};

// OnLoad functions which are executed on page load.  
var OnLoads = [];

////////////////////////////////////////////////////////////////////////////////
// Globals
////////////////////////////////////////////////////////////////////////////////

const Site = window.location.origin // Dev may be `https://localhost:8081`
const CyphrMeSite = "HTTPS://CYPHR.ME"
const ApiURI = "/api/v1"; // JSON api
const BApiURI = ApiURI + "/b"; // Binary api
// Cyphrme logo long
const CyphrmeLogoLong = "/assets/img/cyphrme_long_500x135.png"
const MaxFileSize = 30000000; // 30 mb / 30 million bytes.
const QRCodeURL = "HTTPS://CYPHR.ME/AC"


// Internal CRUUD logic: Create Read Upsert Update Delete. 
//
// Create is creation. If the entity exists, it must error.  Example:
// "/image/create" 
//
// Upsert should be used to add new or edit an existing entity. Does not error
// if already exists.
//
// Update updates an existing record and must error on no existing record. 
//
// Read is a get and is the default/implicit.  Example:  "/image"
//
// Delete Example: "/image/delete"

// API is ordered alphabetically
const API = {
	Post: {
		// Post methods
		Anticounterfeits: ApiURI + "/ac/create",
		ACPageUpdate: ApiURI + "/ac/update",
		ACPagesUpdate: ApiURI + "/acs/update", // Variadic/batch updates
		ACsLatestCoze: ApiURI + "/acs/coze/read", // Batch read for array of ACs id, to pull latest everythings.
		PJCreate: ApiURI + "/pj/create",
		PJUpdate: ApiURI + "/pj/update",
		ClearCookies: ApiURI + "/cookies/clear",
		Comment: ApiURI + "/comment/create",
		CommentDelete: ApiURI + "/comment/delete",
		CommentUpdate: ApiURI + "/comment/update",
		EmailCreate: ApiURI + "/user/email/create", // For pre-invite user account creation.
		EmailPrivateKeyBackup: ApiURI + "/user/email/backup/create", // For emailing backup of private key.
		File: ApiURI + "/file/create",
		FileDelete: ApiURI + "/file/delete", // Alias. Presumed to be a single file in an Array.
		FilesDelete: ApiURI + "/file/delete",
		ImageDelete: ApiURI + "/file/delete", // Alias. Also presumed to be an image w/ thumbnail.
		KeyUploaded: ApiURI + "/key/read", // POST request, Read endpoint. Accepts slice of tmbs.
		UpsertKey: ApiURI + "/key/upsert",
		AddNewUser: ApiURI + "/user/invite/create",
		CancelNewUser: ApiURI + "/user/invite/delete",
		CancelNewUsers: ApiURI + "/users/invite/delete", // variadic
		DeleteKey: ApiURI + "/key/delete",
		DeleteKeys: ApiURI + "/keys/delete", // variadic
		RevokeKey: ApiURI + "/key/revoke",
		RevokeKeyOther: ApiURI + "/key/other/revoke",
		Login: ApiURI + '/login/create',
		Profile: ApiURI + "/user/profile/update",
		ProfilePicture: ApiURI + "/user/profile/picture/update",
		ProfilePage: ApiURI + "/profile", // Read
		PurchaseOrder: ApiURI + "/user/email/purchaseRequest/create",
		ReportCounterfeitUrI: ApiURI + "/report/create",
		CozeUpload: ApiURI + "/coze/upload",
		EmailVerify: ApiURI + "/user/email/verify/create"
	},
	Get: {
		// Get Methods for JSON
		Search: ApiURI + "/s", // Search endpoint for the application.
		SearchCoze: ApiURI + "/s/coze", // Search endpoint for pulling the record's Coze the application.
		Coze: ApiURI + "/coze", // Gets a Coze for a given ID.
		PJJSON: ApiURI + "/pj", // Gets JSON for PJ.
		ACJSON: ApiURI + "/ac", // Gets JSON for AC.
		Acpage: ApiURI + "/acpage", // Gets a acpage for a given ID, and all associated attributes (images, reviews, rating, etc.)
		ACPageInfo: ApiURI + "/acpage_details", // Gets the details for a given acpage. This includes actions and inheritance of other ac's.
		PageHit: ApiURI + "/pagehit", // Although Get request, performs Post like actions for endpoint and adds page hit to the page.
		Key: ApiURI + "/key", // Single KeyStore (not cozekey) by tmb.  e.g. api/v1/key/cLj8vsYtMBwYkzoFVZHBZo6SNL8wSdCIjCKAwXNuhOk    
		CozeKey: ApiURI + "/cozekey", // Single Coze key by tmb.  e.g. api/v1/cozekey/cLj8vsYtMBwYkzoFVZHBZo6SNL8wSdCIjCKAwXNuhOk
		Image: BApiURI + "/image", // Binary Read endpoint.
		File: BApiURI + "/file", // Binary Read endpoint.
		ImageMeta: ApiURI + "/images", // Returns Image Meta
		InviteList: ApiURI + "/invite", // Gets a list of invites for a given account.
		DisplayName: ApiURI + "/display_name", // Gets the current display name for a uad
		EmailVerify: ApiURI + "/user/email/verify", // Where verify email system coze is sent as the url param.
		User: ApiURI + "/user", // Gets the user.
		Profile: ApiURI + "/profile", // Gets the account/profile details for a given root thumbprint. If no associated account exists, The page reports profile is either unverified, or needs to be created. 
		UserActions: ApiURI + "/user/actions", // Gets actions for a given user
		UserKeys: ApiURI + "/user/keys", // Gets the public keys for a given User's AccountID
		UserItems: ApiURI + "/user/items", // Gets items for a given user
		UserModels: ApiURI + "/user/models", // Gets models for a given user
		UserComments: ApiURI + "/user/comments", // Gets comments for a given user
		UserImages: ApiURI + "/user/images", // Gets images for a given user
		UserFiles: ApiURI + "/user/files", // Gets files for a given user
	}
};

// Page is for HTML pages.
const Page = {
	Account: "/account",
	Actions: "/user/actions", // Account log for a given User. 
	Comments: "/comments", // Pagination page for comments on a certain pager.
	CozeKey: "/cozekey", // Coze key, not key store. There is no html KeyStore page (use json for keystore).
	Everything: "/e", // Everything page.  
	Images: "/images", // Pagination page for images on a certain pager.
	PreInviteAccountModal: "/preinvite_account_modal", // Returns the HTML modal.
	PublicProfile: "/user/id", // Public profile. 
	Profile: "/profile", // Public profile.  Append "/ID"
	UserKeys: "/user/keys", // User's active keys.  Append "/ID". 
	UserRevokedKeys: "/user/keys/revoked", // User's active keys. Append "/ID". 
	UserComments: "/user/comments",
	UserImages: "/user/images",
	UserFiles: "/user/files",
	Verify: "/coze", // Coze Verifier.
	IframeAC: "/iframe/ac",
	IframeScan: "/iframe/scan",
};


////////////////////////////////////////////////////////////////////////////////
// Third Party Tools
////////////////////////////////////////////////////////////////////////////////
const RecaptchaPublic = "6Ldn1zYaAAAAAOD8XTJ3w7jd9O5SQcBWggzWnF8o";


////////////////////////////////////////////////////////////////////////////////
// Functions
////////////////////////////////////////////////////////////////////////////////

/**
All pages ready is called on page load.
Should be called on "DOMContentLoaded", and only executes once.
@returns {void}
 */
async function AllPagesReady() {
	console.log("Executing AllPagesReady");
	// Execute all onloads.
	// console.log("Iterating over OnLoads length: " + OnLoads.length);
	// console.log(OnLoads);
	AddOnload(OnloadLast);
	for (var i = 0; i < OnLoads.length; i++) {
		await OnLoads[i]();
	}
};

/**
RemoveOnload removes a function from Onloads. 
@param {function} func 
@returns {void}
 */
function RemoveOnload(func) {
	OnLoads.splice(OnLoads.indexOf(func), 1);
};

/**
AddOnload append a function to Onloads at the bottom.  
@param {function} func 
@returns {void}
 */
function AddOnload(func) {
	OnLoads.push(func);
};

/**
AddOnloadFirst prepends a function to Onloads at the top.  
@param {function} func 
@returns {void}
 */
function AddOnloadFirst(func) {
	OnLoads.unshift(func);
};

// Javascript for site wide (Cyphr.me) functions.
document.addEventListener('DOMContentLoaded', () => {
	AllPagesReady();
});


function OnloadLast() {
	// If an anchor exists in the URL, it scrolls the element into view.
	JumpToAnchor();
	setCozeJSONLink();
	JSONPretty();
	// TODO global copy.
}

// setCozeJSONLink selects all `coze_json_links`, and sets the verify link.
// Assumes 'coze_json_link' and 'coze_json' are encapsulated in a parent element.
function setCozeJSONLink() {
	document.querySelectorAll('.coze_json_link').forEach((item) => {
		item.href = Page.Verify + "?verify&input=" +
			JSON.stringify(JSON.parse(item.parentElement.querySelector('.coze_json').textContent)) +
			"&verify";
	});
}

//////////////////////////////////////////////
//////////////////////////////////////////////
// Logging
// TODO DEPRECATE
// It's too weird in javascript.  
//////////////////////////////////////////////
//////////////////////////////////////////////
var LogLevel = 1;

function SetLogLevel(int) {
	LogLevel = int;
}

// Match https://github.com/rs/zerolog#leveled-logging golang
var Log = {
	Trace(msg, trace) {
		log(msg, 0); // 0
	},
	Debug(msg, trace) {
		log(msg, 1);
	},
	Info(msg) {
		log(msg, 2);
	},
	Warn(msg) {
		log(msg, 3);
	},
	Error(msg) {
		log(msg, 4);
	},
	Fatal(msg) {
		log(msg, 5);
	},
	Panic(msg) {
		log(msg, 6);
	}
}


/**
 log sets the log level for the application.
@param {string}  msg       Message to log.
@param {number}  intLevel  Represents the log level.
@param {boolean} trace     If log is a trace.
 */
function log(msg, intLevel, trace) {
	if (LogLevel >= intLevel) {
		if (trace) {
			console.trace(msg);
		} else {
			console.debug(msg);
			// console.log(msg);
			// console.trace(msg);
		}
	}
};

////////////////////////////////////////////////////////////////////////////////
// Notifications
////////////////////////////////////////////////////////////////////////////////

/**
notification sets the main notification for the user.
@param {string} message        Message to display to the user
@param {string} level          "success" "danger"
 */
function Notification(message, level) {
	var mainAlert = document.querySelector('#cyphrme_toast .toast'); //select id of toast
	// Clear previous style.  
	mainAlert.classList.remove("bg-danger", "text-white", "bg-success");

	if (level == "error") {
		mainAlert.classList.add("bg-danger", "text-white")
	}
	if (level == "success") {
		mainAlert.classList.add("bg-success", "text-white")
	}

	mainAlert.querySelector(".toast-body").innerHTML = message;
	var bsAlert = new bootstrap.Toast(mainAlert); // initialize it
	bsAlert.show(); //show it
	return true;
};

/**
TODO consider rename to Throw, add new function Error. Error should just be:
function Error(error) {
	console.error(error)
	Notification(msg, "error")
}

function Throw(error) {
	Error(error)
	throw error
}

Error shows an error message for the user and stops function execution via
throw.  Error should be used at a high level with consumer readable errors.

Recommended not to catch error thrown by Error so that the stack trace is
logged. This also stops the calling function's execution, just like a
"return", via `throw`. 

Usage:
Instead of 

    console.error(e);
    Cyphrme.Notification(e, "error")
    return;

Just call Error()

    Cyphrme.Error(error);

If "error" is not consumer readable, pass in "msg", a consumer readable error
message. Error "error) itself is still be logged via throw.  Example:
`Cyphrme.Error(e, "JSON is invalid.")`
@param  {error}  error    Javascript error.
@param  {string} [msg]    Optional Message.
@throws {error}           Always throws an error.
 */
function Error(error, msg) {
	if (isEmpty(msg)) {
		msg = error
		console.error("Error: ", error)
	} else {
		console.error("Error: ", error, "Msg: ", msg)
	}
	Notification(msg, "error")
	throw error
}


/**
UTKtoHR takes a UTK (UAD, Timestamp, Key) in uppercase hex form, and
return a human readable timestamp format.
@param   {B64}   utk
@returns {Date}
 */
async function UTKtoHR(utk) {
	// Hacky: Since the way UTK is generated in GO is by slicing up the
	// interpreted bytes, we must first convert this to Hex, to properly interpret
	// and slice up in Javascript.
	let h = Lib.B64ToHex(utk);
	let trim = h.substring(4);
	trim = trim.substring(0, (trim.length - 4))
	return await UnixToHR(parseInt(trim, 16))
}


/**
UnixToHR takes a unix timestamp (In seconds. Passing milliseconds returns
unexpected results), and return a human readable date format.
@param   {number} timestamp        Unix timestamp.
@returns {Date}
 */
async function UnixToHR(timestamp) {
	let options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric'
	}
	return new Date(timestamp * 1000).toLocaleString("en-US", options)
}

/**
IsCyphrmeDigest is a sanitization function that checks if string is digest
length recognized by Cyphr.me. Non-entropic/non-digests can pass this tests.

TODO this is incomplete, should check canonical b64ut encoding. Include Hex
and check each length for the correct encoding characters.
@param   {string}   string  Thing you are checking if it is a hash
@returns {boolean}          True if the thing passed in is a hash.
@throws  {error}            Throws error on empty thing passed in.
*/
function IsCyphrmeDigest(string) {
	if (typeof string === "string") {
		switch (string.length) {
			// b64ut
			case 43:
			case 64:
			case 86:
				// BASE37
			case 50:
			case 74:
			case 99:
				return true;
		}
	}
	return false;
};

/**
ParseSerializedCozeKey returns a parsd object from the given serialized string.

Serialized forms:

alg:izd:d:x:tmb
alg:d:x:tmb
alg:d:x:
alg:d::
alg:x:tmb
alg:tmb
alg:tmb~chk
alg:d::tmb

TODO move this to Coze JS Standard.
@param   {UAD}      uad
@returns {CozeKey}
*/
function ParseSerializedCozeKey(string) {
	// console.debug(string)
	let splits = string.split(":")
	var CozeKey = {}

	switch (splits.length) {
		default:
			return null;
		case 2: // alg:tmb
			CozeKey = {
				alg: splits[0],
				tmb: splits[1],
			}
			break
		case 3: // alg:x:tmb
			CozeKey = {
				alg: splits[0],
				x: splits[1],
				tmb: splits[2],
			}
			break
		case 4: // alg:d:x:tmb
			CozeKey = {
				alg: splits[0],
				d: splits[1],
				x: splits[2],
				tmb: splits[3],
			}
			break
		case 5: // alg:izd:d:x:tmb
			CozeKey = {
				alg: splits[0],
				izd: splits[1],
				d: splits[2],
				x: splits[3],
				tmb: splits[4],
			}
			break
	}

	return CozeKey
}


/**
IsHex accepts an id/string and returns true if the string is of one of the
expected Base16/Uppercase Hex lengths.
@param   {string} id     ID/string being checked.
@returns {void}
 */
function IsHex(id) {
	switch (id.length) {
		case 64:
		case 96:
		case 128:
			return true;
	}
	return false;
};


/**
IsBASE37 accepts an id/string and return true if the string is of one of the
expected BASE37 lengths.
@param   {string} id     ID/string being checked.
@returns {void}
 */
function IsBASE37(id) {
	switch (id.length) {
		case 50:
		case 74:
		case 99:
			return true;
	}
	return false;
};


/**
JSONPretty checks for `.jsonPretty` in the DOM classlist for the page, and
prettifies the text contents of the div.
@returns {void}
*/
async function JSONPretty() {
	let divs = document.querySelectorAll('.jsonPretty')
	divs.forEach(function(item) {
		console.log("JSONPretty", item)
		if (item.textContent == "") {
			return
		}
		let json = JSON.parse(item.textContent)
		item.textContent = JSON.stringify(json, null, 1)
	})
}


/**
Helper function to set URL anchor and scroll to hash anchor. Overwrites existing
anchors and fragment queries if input is set.

Given hash must be prepended with "#".
@param   {string} [hash]   The ID of element to hash link.
@returns {void}
*/
async function JumpToAnchor(hash) {
	let urlHash = window.location.hash
	//console.log("jumpToAnchor, ", hash, urlHash)
	// If current and given are empty, do nothing. 
	if (isEmpty(urlHash) && isEmpty(hash)) {
		return
	}

	if (isEmpty(hash)) {
		hash = urlHash
	}

	// Get everything before fragment query.
	// An anchor is after # and optionally before the next ?.  
	let p = hash.split('?')
	let anchor = p[0].substring(1) // past the "#" symbol

	if (isEmpty(anchor)) {
		return
	}

	let element = document.getElementById(anchor)
	if (element != null) {
		console.log("Jumping to: " + anchor)
		element.scrollIntoView()
	} else {
		//// Debugging
		// console.debug("jumpToAnchor Element not found: " + anchor)
		// return
	}
	element.classList.add("highlight")


	// fragment query is after anchor and ?.
	// Add back to the url if they were set. 
	if (!isEmpty(p[1])) {
		anchor = anchor + "?" + p[1]
	}
	// Don't use `window.location.hash = "#" + anchor`, as URLForm reloads on
	// hashchange. `pushState` does not trigger a reload.
	let url = new URL(window.location.href)
	url.hash = "#" + anchor
	window.history.pushState({}, '', url)
}



/**
Collapse sets a click event listener on the given toggleElement to either
collapse, or expand the given visibleElement.  Uses Bootstrap icons:
bi-dash-square and bi-plus-square (e.g. <i class="bi bi-plus-square"></i>)

ToggleElement may be the icon itself OR a parent of the icon. visibleElement
must be the div being toggled.
@param {string|element} toggleElement    Element that triggers the toggle.
@param {string|element} visibleElement   Element being toggled.
@param {function}       [callback]       Optional callback to execute in event listener.
@param {void}
*/
function Collapse(toggleElement, visibleElement, callback) {
	//console.debug(toggleElement, visibleElement, callback)
	if (typeof toggleElement == "string") {
		var toggleElement = document.getElementById(toggleElement)
	}
	if (typeof visibleElement == "string") {
		var visibleElement = document.getElementById(visibleElement)
	}

	// Change Icon
	toggleElement.addEventListener('click', () => {
		//  toggleElement may be the icon itself of the parent element of the icon.   
		var icon = ""
		// toggleElement is the icon itself.
		if (toggleElement.classList.contains("bi-plus-square") || toggleElement.classList.contains("bi-dash-square")) {
			icon = toggleElement
		} else {
			// Assume toggleElement is the parent of the icon.  
			icon = toggleElement.querySelector(".bi-plus-square")
			if (icon === null) {
				icon = toggleElement.querySelector(".bi-dash-square")
			}
		}

		if (ToggleVisible(visibleElement)) {
			icon.classList.remove("bi-dash-square")
			icon.classList.add("bi-plus-square")
		} else {
			icon.classList.remove("bi-plus-square")
			icon.classList.add("bi-dash-square")
		}
		if (typeof callback === 'function') {
			callback()
		}
	})
}