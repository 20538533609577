"use strict"

import * as Cyphrme from './cyphrme.js'

export {
	GetCurrentURLParams,
	SetPaginationArrows,
	InitPagRec,
	GenTable,
	AddTableRow,
	AddTableRowFirst,
}

/**
@typedef {import('../../../pkg/cozejs/typedef.js').B64} B64
@typedef {import('../../../pkg/cozejs/typedef.js').Can} Can
*/

/**
PagRec holds the 'Paginate' object and the 'Records' for the given page.

- pag: Paginate object with pagination details form the server.
- rec: Records array of objects for the current page.
@typedef  {object}        PagRec
@property {Paginate}      [pag]
@property {Records}       [rec]
*/

/**
Records is an array of objects. The objects are the subject matter for the
current page.
@typedef  {object[]}     Records
 */

/**
Paginate is an object that holds the necessary pagination information for
navigating from the current page. This should match the server's representation
of the paginator.

- pivot: The record ID/pivot for the next page.
- pivot_b: The record ID/pivot for going backwards.
- limit: The limit for the query. 1. how many records to retrieve, and 2. the
  limit for how many records appear per page.
- depth: For hierarchical structures, depth is how deep. 
- order: The order in which the query is being applied. "-" for descending and
  "" for ascending.
- subject: The subject matter for the page. Only: ["uad", "parent", "root"]
- subject_value: Value for subject. 
- has_more: The page has more. Must always in in alignment with `pivot`. (Show
  More button)
- has_prev: The page has previous.  Must always in in alignment with `pivot_b`.
  (Show Previous button)
- record_number: The record number on the page. Must always be in alignment with
  `records` (the record object).
- page: The page number for what page the user currently is on. Must *not* be
  used in calculations.
- limits: Supported limits for the limit dropdown.
- max_limit: Maximum supported limit for the page.
@typedef  {object}        Paginate
@property {B64}           [pivot]
@property {B64}           [pivot_b]
@property {number}        [limit]
@property {number}        [depth]
@property {string}        [order]
@property {string}        [subject]
@property {B64}           [subject_value]
@property {boolean}       [has_more]
@property {boolean}       [has_prev]
@property {number}        [record_number]
@property {number}        [page]
@property {string[]}      [limits]
@property {number}        [max_limit]
 */

/**
URLQueryParameters holds the information from the URL, that is relevant for
query and pagination information.

- params: The current search params, represented as a string.
- page: The page number for the current page.
- order: The order of the search results.
- currentURL: The URLSearchParams object for the current URL.
- leftPR: The left Pivot Record, useful for pagination arrows. E.g. "gt"
- rightPR: The right Pivot Record, useful for pagination arrows.
- type: The container type. E.g. "PJ", "Page", "Item".
@typedef  {object}          URLQueryParameters
@property {string}          params
@property {number}          page
@property {boolean}         order
@property {URLSearchParams} currentURL
@property {string}          [leftPR] 
@property {string}          [rightPR]
@property {string}          [type]
 */


/**
InitPagRec ingests a "response" and sets up PagRec for pages that supports
pagination. 

1. Sets up GUI navigation.
2. Inits PagRec derivative variables.
3. Triggers GUI error on error.
@param   {string|object} res        String/Object of JSON.
@returns {PagRec}
@throws  {error}
 */
async function InitPagRec(res) {
	try {
		if (isEmpty(res)) {
			throw new Error('No records found.')
		}
		if (typeof res !== 'object') {
			res = JSON.parse(res)
		}
		///////////////////
		// Pagination controls and page number display.
		if (isEmpty(res.pag)) {
			console.debug('res.pag is empty')
			return
		}
		if (isEmpty(res.pag.page)) {
			res.pag.page = 1
		}
		document.querySelectorAll('.pageNumber').forEach(function (item) {
			item.textContent = res.pag.page
		})
		setPaginateDropdown(res.pag) // Sets all Sort Order and Limit dropdowns.
		SetPaginationArrows(res) // Sets all forward/backward buttons.

		// Set individual record numbers.  Calculated based on limit and page. 
		res.pag.page_start_record_number = (res.pag.limit * (res.pag.page - 1)) + 1

		return res
	} catch (e) {
		console.error(e)
		Cyphrme.Error('No records found')
	}
}


/**
setPaginateDropdown highlights the current limit on the page.
@param   {number|string} paginate  Number or String. Page limit i.e 10
@returns {void}
 */
function setPaginateDropdown(paginate) {
	// console.debug("setPaginateDropdown:", paginate)

	// Manually create limit dropdown options when `limits` is given in paginate.
	if (!isEmpty(paginate.limits)) {
		document.querySelectorAll('.paginationDropdownLimit').forEach(function (div) {
			div.innerHTML = "" // Clear out default options.
			for (let limit of paginate.limits) {
				let a = document.createElement('a')
				a.classList.add('dropdown-item')
				a.dataset.value = limit
				a.textContent = limit
				div.append(a)
			}
		})
	}

	document.querySelectorAll('.urlFormDropdownLink a').forEach(function (a) {
		let pagCopy = {
			...paginate
		}
		// console.debug("A's:", a)
		let urlParam = a.parentElement.dataset.urlformparam
		// console.debug("urlParam:", urlParam)

		// Add active if current value is the value
		if (pagCopy[urlParam] == stringToType(a.dataset.value)) {
			// console.debug("active")
			a.classList.add('active')
		}
		let canon = ["limit", "order", "piv_comop", "depth", "type"]
		pagCopy[urlParam] = stringToType(a.dataset.value)
		a.href = '?' + GenURLFromPaginate(pagCopy, canon) + '&page=1'
	})
}

/**
stringToType parses a string, into a boolean, integer, or a string.
@param   {string}                s  The URL Form param's value.
@returns {boolean|number|string}
 */
function stringToType(s) {
	if (s.toLowerCase() === 'true') {
		return true
	}
	if (s.toLowerCase() === 'false') {
		return false
	}
	let i = parseInt(s, 10)
	if (Number.isInteger(i)) {
		return i
	}
	return s
}

/**
Accepts a pagination object, that populates the pagination arrows on the page.
Returns a copy of the records, after possible modifications have been made.
Throws error when no objects are passed in.
@param   {PagRec} pagrec
@returns {PagRec}
@throws  {error}
*/
async function SetPaginationArrows(pagrec) {
	// console.log("SetPaginationArrows:", pagrec)
	if (isEmpty(pagrec)) {
		throw new Error("empty pagrec passed in")
	}
	let paginate = pagrec.pag
	let copy = {
		...pagrec.rec
	}
	let urlp = GetCurrentURLParams()
	// console.debug("url Params:", urlp)
	// "Show less arrow/Backward button"
	if (!isEmpty(paginate.pivot_b)) {
		document.querySelectorAll('.showLessBtn').forEach(function (item) {
			Show(item)
			item.href = (item, Cyphrme.Site + window.location.pathname + ("?" + urlp.params + "&pivot=-" + paginate.pivot_b + ('&piv_comop=' + urlp.leftPF) + ('&order=' + urlp.order)))
			if (!isEmpty(urlp.type)) {
				item.href += '&type=' + paginate.type
			}
		})
	}
	// "Show more arrow/Forward button"
	if (!isEmpty(paginate.pivot)) {
		document.querySelectorAll('.showMoreBtn').forEach(function (item) {
			Show(item)
			item.href = (item, Cyphrme.Site + window.location.pathname + ("?" + urlp.params + "&pivot=" + paginate.pivot + ('&piv_comop=' + urlp.rightPF) + ('&order=' + urlp.order) + ('&page=' + (paginate.page + 1))))
			if (!isEmpty(urlp.type)) {
				item.href += '&type=' + paginate.type
			}
		})
	}
	// console.debug(copy)
	return copy
}

/**
getCurrentURLParams returns an object, populated with the current URL parameters
supported.

Example of current support:
{
"params":"&limit=5&depth=2",
"leftPR":"gt",
"rightPR":"lteq",
"page":1,
"order":true,
"currentURL":"pivot=LRdOw3jOBNGSxb2QqWOWT8XyZUi5EoXmNvZff1Yt-UQ&piv_comop=lteq&order=false&page=3"
}
@returns {URLQueryParameters}
 */
function GetCurrentURLParams() {
	let params = ""
	let leftPF = "gt"
	let rightPF = "lteq"
	let page = 1
	let ord = false
	let order = false
	let type = ""
	let current = URLSearchParams
	// console.debug(paginate)
	// Continue Limit & Depth Params, if they exist.
	if (!isEmpty(window.location.search)) {
		current = new URLSearchParams(window.location.search)
		let limit = current.get("limit")
		let depth = current.get("depth")
		// console.debug("url:", url.toString())
		// console.debug("limit:",limit)
		// console.debug("depth:",depth)
		// console.debug("order:",order)
		if (!isEmpty(limit)) {
			params += '&limit=' + limit
		}
		if (!isEmpty(depth)) {
			params += '&depth=' + depth
		}
		if (!isEmpty(current.get("page"))) {
			page = parseInt(current.get("page"), 10)
		}
		if (!isEmpty(current.get("order"))) {
			order = current.get("order")
		}
		if (order === true || order == 'true') {
			leftPF = "lt"
			rightPF = "gteq"
			ord = true
		}
		if (!isEmpty(current.get("type"))) {
			type = current.get("type")
		}
	}
	// console.debug(leftPF)
	return {
		"params": params,
		"leftPF": leftPF,
		"rightPF": rightPF,
		"page": page,
		"order": ord,
		"type": type,
		"currentURL": current
	}
}


/**
GenURLFromPaginate generates a URL Parameter search, meant for pagination
queries. If canon is not set, the URL is generated from the entirety of the
object.
@param {Paginate} paginate
@param {Can}      [canon]    Optional canon fields to generate the URL.
 */
function GenURLFromPaginate(paginate, canon) {
	let keys = canon
	if (isEmpty(canon)) {
		keys = Object.keys(paginate)
	}
	let urlParams = ""
	for (let i = 0; i < keys.length; i++) {
		// support URL flags (e.g. ?order)
		if (isEmpty(keys[i])) {
			continue
		}
		urlParams += '&' + keys[i] // add as flag
		if (!isEmpty(paginate[keys[i]])) {
			urlParams += '=' + paginate[keys[i]] // if value is set, set value on flag
		}
	}
	return urlParams
}


/**
GenTable creates headers and returns the table html element.
@param   {string[]}    headers  Header column names.
@returns {HTMLElement}
 */
function GenTable(headers) {
	headers.unshift('') // Always add first col for checkbox
	let t = document.querySelector(".gen_js_table")
	let th = t.querySelector("thead tr")
	for (var i = 0; i < headers.length; i++) {
		let h = document.createElement("th")
		h.textContent = headers[i]
		th.append(h)
	}
	return t
}


/**
AddTableRow inserts a row into the given table from the parameter `row`.
@param   {HTMLElement}    TableElement.
@param   {HTMLElement[]}  row
@param   {B64}            [id] optional id for setting id on table rows
@returns {void}
 */
function AddTableRow(TableElement, row, id) {
	TableElement.querySelector("tbody").append(createTableRow(row, id))
}

/**
AddTableRowFirst inserts a record at the beginning of the table.
@param   {HTMLElement}    TableElement.
@param   {HTMLElement[]}  row
@param   {B64}            [id] optional id for setting id on table rows
@returns {void}
 */
function AddTableRowFirst(TableElement, row, id) {
	TableElement.querySelector("tbody").prepend(createTableRow(row, id))
}

/**
createTableRow populates a table row, with the given row data. The row is
returned populated, without being added to the table. Returns table row with
populated data.
@param   {HTMLElement[]}    row
@param   {B64}              [id] optional id for setting id on table
rows
@returns {HTMLTableRowElement}
 */
function createTableRow(row, id) {
	let tr = document.createElement("tr")
	if (!isEmpty(id)) {
		tr.id = id
	}
	// Always adds checkbox to first column of the table
	row.unshift(document.querySelector('.table_checkbox').cloneNode(true))
	for (var i = 0; i < row.length; i++) {
		let td = document.createElement("td")
		if (row[i] instanceof HTMLElement) {
			td.append(row[i])
		} else {
			td.innerHTML = row[i]
		}
		tr.append(td)
	}
	return tr
}