"use strict";

export {
	Err,
}

const Err = {
	AccountIDNotSet: "Account ID not set. Please make sure you are using an activated account."
}


// TODO thinking about this: 
// function CheckUAD() {
// 	if (isEmpty(App.UAD)) {
// 		App.Error(App.Err.AccountIDNotSet);
// 	}
// }