"use strict";

import * as Cyphrme from './cyphrme.js';
import * as Cva from './cva.js';
import * as Login from './login.js';
import * as Enum from './error_enum';
import * as Ajax from './ajax.js';

export {
	DownloadWallet,
	EmailWallet,
	UpsertKey,
}

/**
@typedef {import('./cyphrme.js').CozeKey}          CozeKey
 */


/**
DownloadWallet takes Keys in memory and downloads them all in a JSON file.
@param   {object} keys     Keys to download.
@param   {string} [extra]  Extra identifier for filename.
@returns {void}
 */
async function DownloadWallet(keys, extra) {
	if (!isEmpty(extra)) {
		var filename = "CyphrMe_Key_Wallet_" + extra + "_" + Date.now() + ".json";
	} else {
		var filename = "CyphrMe_Key_Wallet_" + Date.now() + ".json";
	}

	// Download link
	let downloadLink = document.createElement("a");
	downloadLink.style.display = "none";
	downloadLink.download = filename;
	downloadLink.href = window.URL.createObjectURL(new Blob([JSON.stringify(keys, null, 1)], {
		type: "application/json"
	}));
	document.body.appendChild(downloadLink);

	// Simulate a click.
	downloadLink.click();
};

/**
EmailWallet takes Keys in memory and emails them all to the given email.
@param   {string} email     Email to send wallet to.
@param   {object} keys      Keys to download.
@returns {void}
 */
async function EmailWallet(email, keys) {
	let formData = new FormData()
	formData.append('wallet', JSON.stringify(keys, 0, 1)) // Send backup of entire wallet.
	// Encapsulate Coze with `email_verify`, as Email Cozies have special
	// unmarshal logic for the PreInvite User Onboarding process.
	formData.append("coze", JSON.stringify({
		"email_verify": await Cva.EmailBackupCreate(email)
	}))
	formData.append("email", Login.AccountDetails.email)
	let resp = await Ajax.FetchPost(Cyphrme.API.Post.EmailPrivateKeyBackup, formData)
	console.debug("Response: ", resp)
}

/**
UpsertKey upserts the given key.
@param   {CozeKey}   ck        Key to be upserted.
@param   {function}  callback  Callback function to be executed on response.
@returns {void}
 */
async function UpsertKey(ck, callback) {
	// Sanitize for only fields that should be signed over.
	let k = {
		alg: ck.alg,
		tmb: ck.tmb,
	}
	if (!isEmpty(ck.iat)) {
		k.iat = ck.iat
	}
	if (!isEmpty(ck.x)) {
		k.x = ck.x
	}
	if (!isEmpty(ck.kid)) {
		k.kid = ck.kid
	}

	if (isEmpty(Login.UAD)) {
		Cyphrme.Error(Enum.Err.AccountIDNotSet)
	}
	let formData = new(FormData)
	formData.append('coze', JSON.stringify(await Cva.KeyUpsert(ck)))
	callback(await Ajax.FetchPost(Cyphrme.API.Post.UpsertKey, formData))
}