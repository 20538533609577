"use strict";

import '../pkg/qrgen~fv=00000000.min.js'; // Namespaced as 'qrgen'.

export {
	CreateQR
};

/**
CreateQR creates a QR code and returns the QR in an HTMLDivElement.
See 'qr_div' in 'labels.css' for more.
@param   {string}           qrText    The text that is being put into the QR code.
@returns {HTMLDivElement}
*/
async function CreateQR(qrText) {
	var qrdiv = document.createElement('div');
	qrdiv.classList.add("qr_div");
	qrdiv.setAttribute('value', qrText);

	// Second parameter is boarder (Values 0-4).
	qrdiv.innerHTML = qrgen.QrCode.encodeText(qrText, qrgen.QrCode.Ecc.LOW).toSvgString(0);
	return qrdiv;
};

